var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"roles"},[(_vm.isTableLoading)?_c('TmpBlock',{staticClass:"mt-3 mb-1",attrs:{"height":"20"}}):_c('TableSettings',{staticClass:"mt-1",attrs:{"loaded-count":_vm.localDataLength,"total-count":_vm.localDataLength,"pluralArr":_vm.pluralArr}},[[(_vm.isShowExport)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.isExportFileLoading,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.exportAll()}}},'v-btn',attrs,false),on),[(!_vm.isExportFileLoading)?_c('v-icon',[_vm._v("mdi-download")]):_c('v-progress-circular',{attrs:{"indeterminate":"","width":2,"size":18,"color":"grey"}})],1)]}}],null,false,1722275192)},[_c('div',[_vm._v("Экспорт всех справочников")])]):_vm._e()]],2),(_vm.isTableLoading)?_c('div',_vm._l((15),function(index){return _c('TmpBlock',{key:index,staticClass:"mb-1",attrs:{"height":"60"}})}),1):_c('v-expansion-panels',{attrs:{"multiple":"","accordion":""}},_vm._l((_vm.localData),function(value,key){return _c('v-expansion-panel',{key:key},[_c('v-expansion-panel-header',{staticClass:"info-modal__sub-title flex-row-reverse"},[_c('div',{staticClass:"d-flex align-center"},[(!_vm.isNeedSave(key))?_c('div',{staticClass:"roles__name"},[_vm._v(" "+_vm._s(value.name)+" "),_c('span',{staticClass:"roles__name-version ml-2"},[_vm._v("Версия "+_vm._s(value.version || 0))])]):_c('div',{staticClass:"roles__name"},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.maskValue),expression:"maskValue"}],attrs:{"value":_vm.localData[key].name,"placeholder":"Введите название справочника","outlined":"","dense":"","hide-details":"","clearable":""},on:{"click":function($event){$event.stopPropagation();},"change":function($event){return _vm.addNewName($event, key)}}})],1),_c('div',{staticClass:"roles__actions d-flex align-center"},[(!_vm.isNeedSave(key) && _vm.isCanEdit)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.createVersion(value)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-table-box-multiple")])],1)]}}],null,true)},[_c('div',[_vm._v("Создать версию справочника")])]):_vm._e(),(_vm.isCanEdit)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();}}},'div',attrs,false),on),[_c('v-file-input',{staticClass:"d-inline-block pa-0 mt-0",attrs:{"accept":"text/csv","hide-input":"","truncate-length":"15","prepend-icon":"mdi-upload"},on:{"change":function($event){return _vm.importCsvFile($event, key)}}})],1)]}}],null,true)},[_c('div',[_vm._v("Импорт справочника")])]):_vm._e(),(!_vm.isNeedSave(key) && _vm.isShowExport)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.isExportFileLoading,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.exportElement(value.guid)}}},'v-btn',attrs,false),on),[(!_vm.isExportFileLoading)?_c('v-icon',[_vm._v("mdi-download")]):_c('v-progress-circular',{attrs:{"indeterminate":"","width":2,"size":18,"color":"grey"}})],1)]}}],null,true)},[_c('div',[_vm._v("Экспорт справочника")])]):_vm._e(),(_vm.isShowDeleteBtn)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteManual(key)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('div',[_vm._v("Удаление справочника")])]):_vm._e()],1)])]),_c('v-expansion-panel-content',{staticClass:"pb-0"},[_c('ManualTable',{attrs:{"id":key}})],1)],1)}),1),(_vm.isCanCreate)?_c('div',{staticClass:"roles__btn d-flex align-center",on:{"click":_vm.addNewManual}},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-plus")]),_c('div',{staticClass:"roles__name"},[_vm._v("Новый справочник")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }