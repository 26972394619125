import { IDictionaryElement } from "@monorepo/catalog/src/views/ServiceDirectories/types/IDictionaryElement";

export const convertManualsFromApi = (manuals: IDictionaryElement[]): { [key: string]: Partial<IDictionaryElement> } => {
  const convertedManuals: { [key: string]: IDictionaryElement } = {};
  manuals.forEach((item) => {
    convertedManuals[item.id] = {
      ...item,
    };
  });
  return convertedManuals;
};

export const convertManualsToApi = (manuals: { [key: string]: Partial<IDictionaryElement> }): IDictionaryElement[] => {
  return Object.entries(manuals).map(([key, value]) => ({
    id: key,
    ...(value as Omit<IDictionaryElement, "id">),
  }));
};
