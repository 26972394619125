



































import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import Manuals from "@monorepo/catalog/src/views/ServiceDirectories/components/Manuals.vue";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import { defineComponent } from "@vue/composition-api";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { breadcrumbs } from "@monorepo/catalog/src/views/ServiceDirectories/constants/breadcrumbs";
import { viewTitle } from "@monorepo/utils/src/variables/projectsData/serviceDirectories/viewTitle";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { mapActions, mapGetters, mapMutations, Module } from "vuex";
import { module as serviceDirectoriesCatalogView } from "@monorepo/catalog/src/store/modules/serviceDirectoriesCatalogView";
import { emptyManualKey } from "@monorepo/catalog/src/views/ServiceDirectories/constants/emptyManualKey";
import { IDictionaryElement } from "@monorepo/catalog/src/views/ServiceDirectories/types/IDictionaryElement";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { v4 as uuid } from "uuid";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";

const getObject = (dictionary: { [key: string]: IDictionaryElement[] }) => {
  return Object.entries(dictionary).reduce((result, [key, value]) => {
    const obj = value.reduce((result, item) => {
      return {
        ...result,
        [key + (item.guid || item.id)]: {
          ...item,
          key,
        },
      };
    }, {} as { [key: string]: IDictionaryElement });
    return Object.assign(result, obj);
  }, {} as { [key: string]: IDictionaryElement });
};

export default defineComponent({
  name: "ServiceDirectoriesView",
  components: {
    Breadcrumbs,
    Manuals,
    Footer,
  },
  data() {
    return {
      breadcrumbs,
      isSaveLoading: false,
      viewTitle,
      key: uuid(),
    };
  },
  computed: {
    ...mapGetters("serviceDirectoriesCatalogView", ["data", "localData", "dictionariesItems", "localDictionariesItems", "isTableLoading"]),
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    checkIsDictionariesChanged() {
      const localData: IDictionaryElement[] = this.localData || [];
      const data: IDictionaryElement[] = this.data || [];

      if (localData.length !== data.length) {
        return true;
      }

      return localData.some((item: IDictionaryElement) => {
        return item?.id?.toString().includes(emptyManualKey);
      });
    },
    checkIsDictionaryItemsChanged() {
      const isChangedKey = Object.keys(this.localDictionariesItems).some((key) => {
        if (!this.dictionariesItems[key]) {
          return true;
        }
      });

      if (isChangedKey) {
        return true;
      }

      const itemsObject = getObject(this.dictionariesItems);
      const localItemsObject = getObject(this.localDictionariesItems);

      if (Object.keys(itemsObject).length !== Object.keys(localItemsObject).length) {
        return true;
      }

      return Object.entries(localItemsObject).some(([key, value]) => {
        const elementFromStore = itemsObject?.[key] as IDictionaryElement;
        if (!elementFromStore) {
          return true;
        }

        if (elementFromStore.code !== value.code) {
          return true;
        }

        if (elementFromStore.name !== value.name) {
          return true;
        }

        if (elementFromStore.description !== value.description) {
          return true;
        }

        return false;
      });
    },
    isShowFooter(): boolean {
      return this.checkIsDictionariesChanged || this.checkIsDictionaryItemsChanged;
    },
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.SERVICE_DIRECTORIES_CREATE);
    },
  },
  methods: {
    ...mapMutations("serviceDirectoriesCatalogView", ["resetAllData", "clearAllItemsData"]),
    ...mapActions("serviceDirectoriesCatalogView", ["createDictionary", "getManualList", "saveDictionaryItems", "deleteDictionary"]),
    clearAllData() {
      this.resetAllData();
    },
    async saveData() {
      try {
        this.isSaveLoading = true;
        const isSaveDict = await this.saveDictionaries();
        if (isSaveDict && (await this.saveLibraries())) {
          showNotification("Данные успешно сохранены", NOTIFICATION_STATUS.SUCCESS);
          await this.refreshView();
        }
      } finally {
        this.isSaveLoading = false;
      }
    },
    async refreshView() {
      this.key = uuid();
      await this.getManualList();
      this.clearAllItemsData();
    },
    async saveDictionaries() {
      const newDictionaries = this.localData.filter((item: IDictionaryElement) => {
        return item?.id?.toString().includes(emptyManualKey);
      });

      if (newDictionaries.some((dict: IDictionaryElement) => !dict.name)) {
        showNotification("Ошибка сохранения справочника: введите название справочника");
        return false;
      }

      const sameNames = new Set();
      const isSameNames = this.localData.some((dict: IDictionaryElement) => {
        if (sameNames.has(dict.name)) {
          return true;
        } else {
          sameNames.add(dict.name);
          return false;
        }
      });

      if (isSameNames) {
        showNotification("Ошибка сохранения справочника: введены одинаковые названия справочников");
        return false;
      }

      const itemsObject = getObject({ "": this.data });
      const localItemsObject = getObject({ "": this.localData });

      const deletedDictionaries = Object.entries(itemsObject).reduce((result, [key, value]) => {
        if (!localItemsObject[key]) {
          result.push(value);
        }
        return result;
      }, [] as IDictionaryElement[]);

      if (!newDictionaries.length && !deletedDictionaries.length) {
        return true;
      }

      return Promise.all([
        ...deletedDictionaries.map((dict: IDictionaryElement) => {
          return this.deleteDictionary(dict.guid);
        }),
      ])
        .then((values) => {
          if (!values.every((value) => !!value)) {
            return [false];
          }
          return Promise.all([
            ...newDictionaries.map((dict: IDictionaryElement) => {
              const item = {
                code: uuid(),
                description: dict.description,
                name: dict.name,
                id: dict.id,
              };
              return this.createDictionary(item);
            }),
          ]);
        })
        .then((values) => values.every((value) => !!value));
    },
    async saveLibraries() {
      const itemsObject = getObject(this.dictionariesItems);
      const localItemsObject = getObject(this.localDictionariesItems);

      const savedLibsIds = Object.entries(localItemsObject)
        .filter(([key, value]) => {
          const elementFromStore = itemsObject?.[key] as IDictionaryElement;
          if (!elementFromStore) {
            return true;
          }

          if (elementFromStore.code !== value.code) {
            return true;
          }

          if (elementFromStore.name !== value.name) {
            return true;
          }

          if (elementFromStore.description !== value.description) {
            return true;
          }

          return false;
        })
        .filter(([, value]) => {
          if (!value.code && !value.name && !value.description) {
            return false;
          }

          return true;
        })
        .reduce((result: Set<string>, [, value]: [string, IDictionaryElement]) => {
          result.add(value.key as string);
          return result;
        }, new Set());

      Object.entries(itemsObject).forEach(([key, value]: [string, IDictionaryElement]) => {
        const elementFromStore = localItemsObject?.[key] as IDictionaryElement;
        if (!elementFromStore) {
          savedLibsIds.add(value.key as string);
        }
      });

      const ids = [...savedLibsIds.values()];

      if (!ids.length) {
        return true;
      }

      return Promise.all(
        ids.map((key) => {
          const guid = this.localData.find((item: IDictionaryElement) => {
            return item.id.toString() === key.toString();
          })?.guid;

          const items =
            this.localDictionariesItems?.[key]
              ?.filter((value: IDictionaryElement) => {
                if (!value.code && !value.name && !value.description) {
                  return false;
                }

                return true;
              })
              .map((item: IDictionaryElement) => {
                return {
                  code: item.code || uuid(),
                  name: item.name || "",
                  description: item.description || "",
                  id: item.id?.toString()?.includes(emptyManualKey) ? undefined : item.id,
                };
              }) ?? [];
          return this.saveDictionaryItems({ items, guid });
        })
      ).then((values) => values.every((value) => !!value));
    },
  },
  setup(props, { root }) {
    useInitTableStoreModule(root, "serviceDirectoriesCatalogView", serviceDirectoriesCatalogView as Module<unknown, unknown>);
    useOpenHistoryMode(root, "serviceDirectoriesCatalogView");
    if (!root.$store.getters["serviceDirectoriesCatalogView/data"].length) {
      root.$store.dispatch("serviceDirectoriesCatalogView/getManualList").catch(console.error);
    }
  },
});
