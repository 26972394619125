







































































import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters, mapMutations } from "vuex";
import mask from "@monorepo/utils/src/directives/mask";
import IdCell from "@monorepo/uikit/src/components/tableViews/IdCell.vue";
import OpenCardButton from "@monorepo/uikit/src/components/tableViews/OpenCardButton.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/serviceDirectories/tableHeaders";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/serviceDirectories/viewTitle";
import { convertManualsFromApi } from "@monorepo/catalog/src/views/ServiceDirectories/utils/convertingManualsApi";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { emptyManualKey } from "@monorepo/catalog/src/views/ServiceDirectories/constants/emptyManualKey";
import { IDictionaryElement } from "@monorepo/catalog/src/views/ServiceDirectories/types/IDictionaryElement";
import { cloneDeep } from "lodash";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";

export default defineComponent({
  components: {
    OpenCardButton,
    IdCell,
  },
  directives: {
    mask,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tableHeaders,
      maskValue: { regex: "^[0-9a-zA-Zа-яА-Я][0-9a-zA-Zа-яА-Я-_ ]*", placeholder: "" },
      viewUniqKey,
      selectedKey: "id",
      selectedIdsElements: {} as Record<string | number, boolean>,
      isSelectAll: false,
      manuals: [] as IDictionaryElement[],
    };
  },
  watch: {
    guid: {
      immediate: true,
      handler(value: string) {
        this.getLibrary(value);
      },
    },
    isSelectAll(value) {
      if (value) {
        this.selectAll(this.manuals);
      } else if (value === false) {
        this.clearAll();
      }
    },
    localDictionariesItems(value) {
      this.manuals = value[this.id];
    },
  },
  computed: {
    ...mapGetters("serviceDirectoriesCatalogView", {
      data: "localData",
      localDictionariesItems: "localDictionariesItems",
    }),
    ...mapGetters("auth", ["user"]),
    guid(): string {
      return convertManualsFromApi(this.data || [])[this.id]?.guid ?? "";
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.SERVICE_DIRECTORIES_MODIFY);
    },
    isDisabledFields(): boolean {
      return !this.isCanEdit;
    },
    // isSelectAllElement: {
    //   get(): boolean {
    //     return this.isSelectAll;
    //   },
    //   set(value: boolean) {
    //     this.$store.dispatch("serviceDirectoriesCatalogView/setIsSelectAll", value);
    //   },
    // },
  },
  methods: {
    ...mapActions("serviceDirectoriesCatalogView", ["getManualsItemsList"]),
    ...mapMutations("serviceDirectoriesCatalogView", ["setDictionaryItem", "setLocalDictionaryItem"]),
    async getLibrary(value: string) {
      if (this.localDictionariesItems[this.id]) {
        return;
      }
      if (value) {
        const data = await this.getManualsItemsList(value);
        this.manuals = !this.isDisabledFields ? data.concat(this.emptyLine(data.length - 1)) : data;
        this.setDictionaryItem({ key: this.id, data: cloneDeep(this.manuals) });
      } else {
        this.manuals = !this.isDisabledFields ? [this.emptyLine(0)] : [];
        this.saveManualsToStore();
      }
    },
    clickCheckbox(id: string | number) {
      if (this.isSelectAll) {
        this.selectedIdsElements = {};
      }

      this.selectedIdsElements = {
        ...this.selectedIdsElements,
        [id]: !this.selectedIdsElements[id],
      };

      return null;
    },
    selectAll(value: IDictionaryElement[]) {
      this.selectedIdsElements = value.reduce((result: Record<string | number, boolean>, item) => {
        const key = item[this.selectedKey as keyof IDictionaryElement] as string;
        result[key] = true;
        return result;
      }, {});
    },
    clearAll() {
      this.selectedIdsElements = {};
    },
    emptyLine(index: number) {
      return {
        id: emptyManualKey + index,
        code: "",
        name: "",
        description: "",
        version: 0,
      };
    },
    saveManualsToStore() {
      this.setLocalDictionaryItem({
        key: this.id,
        data: cloneDeep(this.manuals),
      });
    },
    addEmptyBottomLine(index: number) {
      const newLine = this.emptyLine(index);
      this.manuals = this.manuals.concat(newLine);
      this.saveManualsToStore();
    },
    deleteRow(index: number) {
      this.manuals = this.manuals.filter((element, indexArr) => indexArr !== index);
      this.saveManualsToStore();
      if (this.manuals.length === 0 || this.manuals.length === index) {
        this.addEmptyBottomLine(0);
      }
    },
    inputChangeCb(value: string, index: number) {
      if (value && index === this.manuals.length) {
        this.addEmptyBottomLine(index);
      }
    },
  },
});
