































































































import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import ManualTable from "@monorepo/catalog/src/views/ServiceDirectories/components/ManualTable.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/serviceDirectories/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/serviceDirectories/viewTitle";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { convertManualsFromApi, convertManualsToApi } from "@monorepo/catalog/src/views/ServiceDirectories/utils/convertingManualsApi";
import { v4 as uuid } from "uuid";
import { emptyManualKey } from "@monorepo/catalog/src/views/ServiceDirectories/constants/emptyManualKey";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import { cloneDeep } from "lodash";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import mask from "@monorepo/utils/src/directives/mask";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { IDictionaryElement } from "@monorepo/catalog/src/views/ServiceDirectories/types/IDictionaryElement";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";

export default defineComponent({
  name: "Manuals",
  components: {
    ManualTable,
    TableSettings,
  },
  directives: {
    mask,
  },
  data() {
    return {
      text: "",
      pluralArr: ["справочника", "справочников", "справочников"],
      tableHeaders,
      viewTitle,
      viewUniqKey,
      console,
      maskValue: {
        regex: "^[0-9a-zA-Zа-яА-Я№][0-9a-zA-Zа-яА-Я-_№ ]*[0-9a-zA-Zа-яА-Я№]",
        placeholder: "",
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("app", ["isExportFileLoading"]),
    ...mapGetters("serviceDirectoriesCatalogView", ["data", "isTableLoading"]),
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.SERVICE_DIRECTORIES_EXPORT);
    },
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.SERVICE_DIRECTORIES_CREATE);
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.SERVICE_DIRECTORIES_MODIFY);
    },
    isShowDeleteBtn(): boolean {
      return isAuthorityExist(this.user, authorities.SERVICE_DIRECTORIES_DELETE);
    },
    isShowFooter(): boolean {
      return false;
    },
    localDataLength(): number {
      return Object.keys(this.localData || {}).length;
    },
    localData: {
      get(): { [key: string]: Partial<IDictionaryElement> } {
        return convertManualsFromApi(this.$store.getters["serviceDirectoriesCatalogView/localData"]);
      },
      set(value: { [key: string]: Partial<IDictionaryElement> }) {
        this.$store.commit("serviceDirectoriesCatalogView/setLocalData", {
          data: convertManualsToApi({
            ...value,
          }),
        });
      },
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    ...mapActions("serviceDirectoriesCatalogView", ["changeDictVersion"]),
    isNeedSave: (key: string) => key.includes(emptyManualKey),
    exportElement(guid: string) {
      this.openNewWindow(`${getFullPath(QUERY_PATH.GET_SERVICE_DICTIONARY_LIST)}/export/csv/${guid}`);
    },
    exportAll() {
      this.openNewWindow(`${getFullPath(QUERY_PATH.GET_SERVICE_DICTIONARY_LIST)}/export/pdf`);
    },
    createVersion(item: IDictionaryElement) {
      this.changeDictVersion(item.guid);
    },
    addNewManual() {
      this.localData = {
        ...this.localData,
        [emptyManualKey + " " + uuid()]: {
          name: "",
          code: "",
          description: "",
        },
      };
    },
    async importCsvFile(file: File, key: string) {
      if (file) {
        const formData = new FormData();
        let isNeedRewrite = false;
        let name = this.localData[key]?.name ? this.localData[key]?.name : file?.name?.split(".")[0];
        formData.append("file", file);

        if (!this.isNeedSave(key)) {
          isNeedRewrite = true;
        }

        this.addNewName(name || "", key);
        await this.$store.dispatch("serviceDirectoriesCatalogView/importCsvFile", {
          name,
          file: formData,
          id: isNeedRewrite ? this.localData[key]?.guid : null,
        });
        this.$emit("import");
      }
    },
    addNewName(value: string, key: string) {
      const manuals = cloneDeep(this.localData);
      (manuals[key] as { name: string }).name = value;
      this.localData = manuals;
    },
    deleteManual(key: string) {
      const manuals = cloneDeep(this.localData);
      delete manuals[key];
      this.localData = manuals;
    },
    openTmpModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    getExportData() {
      return;
    },
  },
});
